import React from 'react';
import { StyledSwitch } from './utils.styles';


const LangSwitch = ({callback}) => {
    return (
        <StyledSwitch>

        </StyledSwitch>
    );
};

export default LangSwitch;